<template>
  <div class="swiper-list" @mouseleave="leave()">
    <!-- 商城菜单导航 -->
    <div class="category">
      <div class="sort">
        <li
          class="item"
          v-for="(item, index) in categoryList"
          :key="index"
          @mouseenter="enter(index)"
          :class="index === current ? 'bg-color' : ''"
        >
          <span class="name">
            {{ item.name }}
          </span>
          <i class="fa fa-angle-right fa-lg"></i>
        </li>
      </div>
    </div>
    <!-- 菜单栏子类 -->
    <div
      class="sortCon"
      v-if="seen || isSort"
      @mouseenter="enter1()"
      @mouseleave="leave1()"
    >
      <div class="erSort">
        <a
          class="item"
          v-for="(item, index) in categoryCurrent.children"
          :key="index"
          :href="'/home/goodsList?id=' + item.id"
          target="_blank"
        >
          <div class="picture">
            <img :src="item.pcimage" />
          </div>
          <span class="childName">{{ item.name }}</span>
        </a>
      </div>
    </div>
    <!-- 轮播图 -->
    <div class="swiper-container">
      <!-- swiperOptions  配置轮播插件的参数-->
      <swiper :options="swiperOption" class="swiper">
        <swiper-slide v-for="(item, index) in swiperImages" :key="index">
          <a :href="item.adv_link" target="_blank">
            <img :src="item.adv_code" class="swiperImg" />
          </a>
        </swiper-slide>
        <!-- Optional controls -->
        <!-- 分页器 -->
        <div class="swiper-pagination" slot="pagination"></div>
        <!-- 左箭头-->
        <div class="swiper-button-prev" slot="button-prev"></div>
        <!-- 右箭头 -->
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
  import { swiper, swiperSlide } from 'vue-awesome-swiper'
  import 'swiper/swiper-bundle.css'
  export default {
    name: "swiperList",
    components: {
      swiper,
      swiperSlide
    },
    data() {
      return {
        seen: false, //是否显示菜单分类
        current: -1, //动态给菜单导航加背景色
        categoryCurrent: {}, //当时激活的子类
        isSort: false,
        //配置轮播插件的参数
        swiperOption: {
          loop: false, //循环轮播
          spaceBetween: 30,
          grabCursor: true, //鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状 
          autoplay: {
            delay: 2500,
            disableOnInteraction: false
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }
        }
      }
    },
    props: {
      categoryList: {
        type: Array
      },
      swiperImages: {
        type: Array
      }
    },
    methods: {
      //鼠标移入菜单栏
      enter(index) {
        this.current = index
        this.seen = true
        this.categoryCurrent = this.categoryList[index]
      },
      //鼠标移出菜单栏
      leave() {
        this.current = -1
        this.seen = false
      },
      leave1() {
        this.seen = false
        this.isSort = false
      },
      enter1() {
        this.isSort = true
      }
    }
  }
</script>

<style lang="less" scoped>
.swiper-list {
  width: 1200px;
  height: 420px;
  margin: 0 auto;
  position: relative;
  padding-top: 20px;

  .swiper-container {
    width: 990px;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    margin-left: 10px;
    background: rgba(0, 0, 0, 0.137);

    .swiper-pagination {
      left: 50%;
      bottom: 2%;
      transform: translate(-50%);
      --swiper-pagination-color: #fff;
    }

    .swiper-button-prev,
    .swiper-button-next {
      color: #fff;
    }

    /deep/ .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      margin-right: 10px;
    }

    .swiperImg {
      width: 100%;
      height: 100%;
    }
  }

  .category {
    width: 200px;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background: #fefefe;
    box-sizing: border-box;
    border: 2px solid #fff;

    .sort {
      width: 100%;
      height: 100%;
      color: #fff;
      padding: 14px 0;
      box-sizing: border-box;
    }

    .item {
      height: 39px;
      padding: 0px 21px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      cursor: pointer;

      .name {
        width: 150px;
        font-size: 14px;
        color: #333;
      }

      .fa-angle-right {
        color: #333;
      }
    }
  }

  .bg-color {
    background: #e3e4e5;
  }

  .sortCon {
    box-sizing: border-box;
    position: absolute;
    top: 0px;
    left: 200px;
    width: 1000px;
    height: 100%;
    background: #fff;
    z-index: 99;
    box-shadow: 5px 1px 10px rgba(0, 0, 0, 0.2);
    border: 1px solid #f2f2f2;
    border-left: 0;
    border-right: 0;
    padding: 20px 30px;

    .erSort {
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 400px;
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 40px;
        margin-bottom: 40px;

        &:hover {
          .childName {
            color: #e1251b;
          }
        }

        .childName {
          display: block;
          width: 100px;
          font-size: 14px;
          color: #333;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: 20px;
        }
      }
    }

    .picture {
      width: 50px;
      height: 50px;

      img {
        width: 50px;
        height: 50px;
      }
    }
  }
}
</style>